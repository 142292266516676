import React from "react";
import { Navigate, Route, Routes } from "react-router";

import RequireAuth from "./components/RequireAuth";
import UnauthorizedPage from "./pages/errors/UnauthorizedPage";
import PresencePage from "./pages/presence/PresencePage";

export enum ERoutes {
  unauthorized = "/unauthorized",
  presence = "/presence",
  periode = "/periode",
}

const PeriodePage = React.lazy(() => import("./pages/periode/PeriodePage"));

export const AppRouter = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={`${ERoutes.periode}`}
          element={
            <RequireAuth>
              <PeriodePage />
            </RequireAuth>
          }
        >
          <Route
            path={`:id`}
            element={
              <RequireAuth>
                <PeriodePage />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ERoutes.presence}>
          <Route
            path={`:id`}
            element={
              <RequireAuth>
                <PresencePage />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={ERoutes.unauthorized} element={<UnauthorizedPage />} />
        <Route
          path={"*"}
          element={
            <RequireAuth>
              <Navigate to={ERoutes.periode} replace />
            </RequireAuth>
          }
        ></Route>
      </Routes>
    </React.Suspense>
  );
};
