import { FormGenerator, IFormGeneratorProps } from "nsitools-react";
import React from "react";

export interface BasicFormGeneratorProps<T> extends IFormGeneratorProps<T> {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const BasicFormGenerator = <T,>({
  children,
  className,
  style,
  ...props
}: BasicFormGeneratorProps<T>) => {
  return (
    <FormGenerator
      boldLabel={true}
      labelAlignment={"left"}
      inline={false}
      large={true}
      forceOneColumn={true}
      fill={true}
      {...props}
    >
      {children}
    </FormGenerator>
  );
};

export default BasicFormGenerator;
