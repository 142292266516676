import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useMemo } from "react";
import { ERoutes } from "../AppRouter";

// Hook
export function useRouter() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const openNewTab = React.useCallback((route: string) => {
    let url = window.location.pathname.replace(window.location.hash, "");
    if (window.location.hash) {
      if (!url.startsWith("/")) {
        url += "/#";
      } else {
        url += "#";
      }
    }
    if (!route.startsWith("/")) {
      url += "/";
    }
    url += route;
    window.open(url);
  }, []);

  const editRouteParams = React.useMemo(() => {
    return {
      id,
    };
  }, [id]);

  const goToPage = React.useCallback(
    (route: ERoutes) => {
      navigate(`${route}`);
    },
    [navigate]
  );

  const goToEditPage = React.useCallback(
    (route: ERoutes, id: number) => {
      navigate(`${route}/${id}`);
    },
    [navigate]
  );

  const goToEditPageWithTreeView = React.useCallback(
    (route: ERoutes, id: number, treeViewType: string) => {
      navigate(`${route}/${id}${treeViewType}`);
    },
    [navigate]
  );

  const getEditRoute = React.useCallback(
    (route: ERoutes, id: number, queryParams: Object = null) => {
      const queryString = queryParams
        ? "?" +
          Object.keys(queryParams)
            .map((k) => k + "=" + encodeURIComponent(queryParams[k]))
            .join("&")
        : "";
      return `${route}/${id}/${queryString}`;
    },
    []
  );

  const currentBaseRoute = React.useMemo(
    () => "/" + location.pathname.split("/")[1],
    [location.pathname]
  );

  const goToEditPageOnOtherTab = React.useCallback(
    (route: ERoutes, id: number) => {
      const win = window.open("#" + getEditRoute(route, id), "_blank");
      win.focus();
    },
    [getEditRoute]
  );

  const goToPageOnOtherTab = React.useCallback((route: string) => {
    const win = window.open("#" + route, "_blank");
    win.focus();
  }, []);

  const addQueryObject = React.useCallback(
    (route: string, queryParams: Object) => {
      const queryString =
        "?" +
        Object.keys(queryParams)
          .map((k) => k + "=" + encodeURIComponent(queryParams[k]))
          .join("&");
      return `${route}/${queryString}`;
    },
    []
  );

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      push: (to: string) => navigate(to),
      replace: (to: string) => navigate(to, { replace: true }),
      navigate,
      pathname: location.pathname,
      location,
      openNewTab,
      editRouteParams,
      goToPage,
      getEditRoute,
      goToEditPage,
      goToEditPageOnOtherTab,
      goToPageOnOtherTab,
      addQueryObject,
      goToEditPageWithTreeView,
      currentBaseRoute,
    };
  }, [
    navigate,
    location,
    openNewTab,
    editRouteParams,
    goToPage,
    getEditRoute,
    goToEditPage,
    goToEditPageOnOtherTab,
    goToPageOnOtherTab,
    addQueryObject,
    goToEditPageWithTreeView,
    currentBaseRoute,
  ]);
}
