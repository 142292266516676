import "./App.css";

import React from "react";
import styled from "styled-components";

import { AppRouter } from "./AppRouter";
import AppShell from "./AppShell";
import { DialogRoot } from "./components/DialogRoot";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <Container>
      <AppShell>
        <AppRouter />
      </AppShell>
      <DialogRoot />
    </Container>
  );
}

export default App;
