import { useQuery } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import React from "react";

import { useCommonHooks } from "../useCommonHooks";

export function useClasseHoraireQuery() {
  const { apis } = useCommonHooks();
  const {
    isLoading,
    data,
    refetch: refetchClasseHoraires,
  } = useQuery(["classe-horaires"], async () => {
    return apis.classeHoraire.getClasseHorairesByCurrentUser();
  });

  const dayAndHourInTheFuture = React.useCallback(
    (day: string, hour: string) => {
      const now = new Date();
      const nowDay =
        now.getFullYear() +
        "-" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(now.getDate()).padStart(2, "0");
      const nowHour = now.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      if (day > nowDay) {
        return true;
      }

      if (day < nowDay) {
        return false;
      }

      if (hour <= nowHour) {
        return false;
      }

      return true;
    },
    []
  );

  const centres = React.useMemo(
    () =>
      uniqBy(
        data?.map((d) => {
          return { idCentre: d.idcentre, nomCentre: d.nomCentre };
        }),
        "idCentre"
      ).sort((a, b) => a.nomCentre.localeCompare(b.nomCentre)),
    [data]
  );

  const classes = React.useMemo(
    () =>
      uniqBy(
        data?.map((d) => {
          return {
            idClasse: d.idclasse,
            nomClasse: d.nomClasse,
            codeClasse: d.codeClasse,
            presenceComplete:
              data?.filter(
                (de) => de.idclasse === d.idclasse && !d.presenceComplete
              ).length === 0,
          };
        }),
        "idClasse"
      ).sort((a, b) => a.codeClasse.localeCompare(b.codeClasse)),
    [data]
  );

  const classeMatieres = React.useMemo(
    () =>
      uniqBy(
        data?.map((d) => {
          return {
            idClasseMatiere: d.idclasseMatiere,
            nomClasseMatiere: d.nomClasseMatiere,
            codeClasseMatiere: d.codeClasseMatiere,
            presenceComplete: d.presenceComplete,
          };
        }),
        "idClasseMatiere"
      ).sort((a, b) => a.codeClasseMatiere.localeCompare(b.codeClasseMatiere)),
    [data]
  );

  const jours = React.useMemo(
    () =>
      uniqBy(
        data?.map((d) => {
          return {
            jour: d.jour,
            label: d.jourLabel,
          };
        }),
        "label"
      ).sort((a, b) => a.jour.localeCompare(b.jour)),
    [data]
  );

  const heures = React.useMemo(
    () =>
      uniqBy(
        data?.map((d) => {
          return {
            heure: d.heureDebut,
            label: d.heureLabel,
          };
        }),
        "heure"
      ).sort((a, b) => a.heure.localeCompare(b.heure)),
    [data]
  );

  const getJoursByCentre = React.useCallback(
    (idCentre: number) =>
      uniqBy(
        data
          ?.filter((d) => d.idcentre === idCentre)
          ?.map((d) => {
            return {
              jour: d.jour,
              label: d.jourLabel,
            };
          }),
        "label"
      ).sort((a, b) => a.jour.localeCompare(b.jour)),
    [data]
  );

  const getHeuresByCentreAndJour = React.useCallback(
    (idCentre: number, jour: string) =>
      uniqBy(
        data
          ?.filter((d) => d.idcentre === idCentre && d.jour === jour)
          ?.map((d) => {
            return {
              heure: d.heureDebut,
              label: d.heureLabel,
            };
          }),
        "heure"
      ).sort((a, b) => a.heure.localeCompare(b.heure)),
    [data]
  );

  const getClassesByCentreJourHeure = React.useCallback(
    (idCentre: number, jour: string, heure: string) =>
      uniqBy(
        data
          ?.filter(
            (d) =>
              d.idcentre === idCentre &&
              d.jour === jour &&
              d.heureDebut === heure
          )
          ?.map((d) => {
            return {
              idClasse: d.idclasse,
              nomClasse: d.nomClasse,
              codeClasse: d.codeClasse,
              presenceComplete:
                data?.filter(
                  (de) => de.idclasse === d.idclasse && !d.presenceComplete
                ).length === 0,
              inTheFuture: dayAndHourInTheFuture(d.jour, d.heureDebut),
            };
          }),
        "idClasse"
      ).sort((a, b) => a.codeClasse.localeCompare(b.codeClasse)),
    [data, dayAndHourInTheFuture]
  );

  const getClasseMatieresByCentreJourHeureClasse = React.useCallback(
    (idCentre: number, jour: string, heure: string, idClasse: number) =>
      uniqBy(
        data
          ?.filter(
            (d) =>
              d.idcentre === idCentre &&
              d.jour === jour &&
              d.heureDebut === heure &&
              d.idclasse === idClasse
          )
          ?.map((d) => {
            return {
              idClasseMatiere: d.idclasseMatiere,
              nomClasseMatiere: d.nomClasseMatiere,
              codeClasseMatiere: d.codeClasseMatiere,
              presenceComplete: d.presenceComplete,
              inTheFuture: dayAndHourInTheFuture(d.jour, d.heureDebut),
            };
          }),
        "idClasseMatiere"
      ).sort((a, b) => a.codeClasseMatiere.localeCompare(b.codeClasseMatiere)),
    [data, dayAndHourInTheFuture]
  );

  const getClasseHoraireById = React.useCallback(
    (idClasseHoraire: number) =>
      data?.find((d) => d.idclasseHoraire === idClasseHoraire),
    [data]
  );

  const isClasseHoraireInTheFuture = React.useCallback(
    (idClasseHoraire: number) => {
      const ch = getClasseHoraireById(idClasseHoraire);
      const now = new Date();

      if (!ch) {
        return false;
      }

      const chDateFin = ch.classeHoraireFin;
      var chDateFinTimezoneOffset = chDateFin.getTimezoneOffset() * 60000;
      const chDateFinWithoutTimezone = new Date(
        chDateFin.getTime() + chDateFinTimezoneOffset
      );

      const chDateDebut = ch.classeHoraireDebut;
      var chDateDebutTimezoneOffset = chDateDebut.getTimezoneOffset() * 60000;
      const chDateDebutWithoutTimezone = new Date(
        chDateDebut.getTime() + chDateDebutTimezoneOffset
      );

      if (chDateDebutWithoutTimezone > now) {
        return true;
      }

      if (chDateFinWithoutTimezone < now) {
        return false;
      }

      if (
        chDateDebutWithoutTimezone <= now &&
        chDateFinWithoutTimezone >= now
      ) {
        return false;
      }

      return true;
    },
    [getClasseHoraireById]
  );

  const getEarlierClasseHoraireIncompletes = React.useCallback(
    (idClasseHoraire: number) => {
      const ch = getClasseHoraireById(idClasseHoraire);

      const earlierCh = data.filter(
        (d) =>
          d.idcentre === ch.idcentre &&
          d.idclasse === ch.idclasse &&
          d.idclasseMatiere === ch.idclasseMatiere &&
          d.jour === ch.jour &&
          d.heureDebut < ch.heureDebut &&
          d.presenceComplete === false
      );

      return earlierCh.sort((a, b) => a.heureDebut.localeCompare(b.heureDebut));
    },
    [data, getClasseHoraireById]
  );

  const isEarlierClasseHoraireIncomplete = React.useCallback(
    (idClasseHoraire: number) => {
      return getEarlierClasseHoraireIncompletes(idClasseHoraire).length > 0;
    },
    [getEarlierClasseHoraireIncompletes]
  );

  return {
    data,
    centres,
    classes,
    classeMatieres,
    jours,
    heures,
    getJoursByCentre,
    getHeuresByCentreAndJour,
    getClassesByCentreJourHeure,
    getClasseMatieresByCentreJourHeureClasse,
    getClasseHoraireById,
    isClasseHoraireInTheFuture,
    getEarlierClasseHoraireIncompletes,
    isEarlierClasseHoraireIncomplete,
    refetchClasseHoraires,
    isLoading,
  };
}
