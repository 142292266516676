import { useTl } from "./useTl";
import { useRouter } from "./useRouter";
import { useAuth } from "../contexts/AuthContext";
import { useGlobalData } from "../contexts/GlobalDataContext";
import { useApiService } from "./useApiService";
import { CentreApi, ClasseHoraireApi, PresenceApi, UserApi } from "../api";
import { useDialog } from "../contexts/DialogContext";

export function useCommonHooks() {
  return {
    ...useTl(),
    router: useRouter(),
    ...useAuth(),
    ...useGlobalData(),
    ...useDialog(),
    // ...useManageValidationErrors(),
    // validators: useValidators(),
    // theme: getTheme("base"),
    apis: {
      user: useApiService(UserApi),
      centre: useApiService(CentreApi),
      classeHoraire: useApiService(ClasseHoraireApi),
      presence: useApiService(PresenceApi),
    },
  };
}
