import styled from "styled-components";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  TouchEvent,
} from "react";
import classNames from "classnames";

const Container = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  height: calc(100% - 50px - 80px);
  z-index: 19;
  //not less than 12px and not more than 20px
  font-size: min(20px, max(12px, 1vh));
  padding: calc(2 * min(20px, max(12px, 1vh))) 0;

  > .letters {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.cursor-pointer {
      cursor: pointer;
    }

    > .letter {
      flex: 1;
      padding: 0 10px;
      position: relative;
      transition: transform 0.2s ease-in-out;
      transform-origin: 60%;

      &.letter-disabled {
        opacity: 0.3;
      }
    }
  }
`;

function getNumHiddenHalves(numHiddenLetters: number, total: number) {
  if (numHiddenLetters > total / 2) {
    return (
      1 +
      getNumHiddenHalves(numHiddenLetters % (total / 2), Math.ceil(total / 2))
    );
  }
  return 0;
}

function stringToNumber(value?: string): number {
  return Number(value?.match(/[\.\d]+/)[0]);
}

export type AlphabeticScrollbarProps = {
  onLetterSelected: (letter: string) => void | Promise<void>;
  validLetters: string[];
  disableInvalidLetters?: boolean;
  alphabet?: string[];
  overflowDivider?: string;
  prioritizeHidingInvalidLetters?: boolean;
  letterMagnification?: boolean;
  magnificationMultiplier?: number;
  magnificationCurve?: number[];
  magnifyDividers?: boolean;
  exactX?: boolean;
  navigateOnHover?: boolean;
  letterSpacing?: number | string;
};

export const AlphabeticScrollbar = ({
  onLetterSelected,
  validLetters,
  disableInvalidLetters = false,
  alphabet = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
  overflowDivider = ".",
  prioritizeHidingInvalidLetters = false,
  letterMagnification = true,
  magnificationMultiplier = 2,
  magnificationCurve = [1, 0.7, 0.5, 0.3, 0.1],
  magnifyDividers = true,
  exactX = false,
  navigateOnHover = false,
  letterSpacing,
}: AlphabeticScrollbarProps) => {
  const componentActive = useRef(false);
  const [activeLetter, setActiveLetter] = useState<string>();
  const lastTriggeredLetter = useRef<string>();
  const visualLetterIndex = useRef<number>();
  const [visibleLetters, setVisibleLetters] = useState<string[]>([]);
  const [lettersShortened, setLettersShortened] = useState(false);

  const lastHeight = useRef<number>();

  const isValid = useCallback(
    (letter: string) =>
      validLetters?.includes(letter) !== false || letter === overflowDivider,
    [overflowDivider, validLetters]
  );

  const checkVisibleLetter = useCallback(
    (force?: boolean) => {
      if (!ref.current) throw Error("ref.current is undefined");
      let height = ref.current.clientHeight;
      if (!force && height === lastHeight.current) {
        return;
      }

      lastHeight.current = height;

      let newAlphabet = alphabet;
      let letterSpacingAsNumber = 0;
      let letterSize = stringToNumber(
        getComputedStyle(ref.current.parentElement).getPropertyValue(
          "font-size"
        )
      );

      if (letterMagnification) {
        letterSize = letterSize * magnificationMultiplier;
      }

      //Calculate actual letter spacing
      if (typeof letterSpacing === "number") {
        letterSpacingAsNumber = letterSpacing;
      } else if (typeof letterSpacing === "string") {
        letterSpacingAsNumber = stringToNumber(letterSpacing);
        if (letterSpacing.endsWith("%")) {
          letterSpacingAsNumber = height * (letterSpacingAsNumber / 100);
        }
      }

      letterSize = letterSize + letterSpacingAsNumber;

      //Remove invalid letters (if set and necessary)
      if (
        prioritizeHidingInvalidLetters &&
        !!validLetters &&
        height / letterSize < newAlphabet.length
      ) {
        newAlphabet = validLetters;
      }

      //Check if there is enough free space for letters
      const shouldShortenedLetters = height / letterSize < newAlphabet.length;
      setLettersShortened(shouldShortenedLetters);
      if (shouldShortenedLetters) {
        const numHiddenLetters =
          newAlphabet.length - Math.floor(height / letterSize);
        if (numHiddenLetters === newAlphabet.length) newAlphabet = [];

        //determine how many letters to hide
        const hiddenHalves =
          getNumHiddenHalves(numHiddenLetters, newAlphabet.length) + 1;
        // (this.magnifyDividers || numHiddenLetters > newAlphabet.length - 2 ? 1 : 0);

        //split alphabet into two halves
        let alphabet1 = newAlphabet.slice(0, Math.ceil(newAlphabet.length / 2));
        let alphabet2 = newAlphabet
          .slice(Math.floor(newAlphabet.length / 2))
          .reverse();

        for (let i = 0; i < hiddenHalves; i++) {
          alphabet1 = alphabet1.filter((l, i) => i % 2 === 0);
          alphabet2 = alphabet2.filter((l, i) => i % 2 === 0);
        }

        //insert dots between letters
        alphabet1 = alphabet1.reduce((prev, curr, i) => {
          if (i > 0) {
            if (overflowDivider) prev.push(overflowDivider);
          }
          prev.push(curr);
          return prev;
        }, []);
        alphabet2 = alphabet2.reduce((prev, curr, i) => {
          if (i > 0) {
            if (overflowDivider) prev.push(overflowDivider);
          }
          prev.push(curr);
          return prev;
        }, []);

        if (alphabet.length % 2 === 0 && overflowDivider)
          alphabet1.push(overflowDivider);
        newAlphabet = alphabet1.concat(alphabet2.reverse());
      }

      setVisibleLetters(newAlphabet);
    },
    [
      alphabet,
      letterMagnification,
      letterSpacing,
      magnificationMultiplier,
      overflowDivider,
      prioritizeHidingInvalidLetters,
      validLetters,
    ]
  );

  const ref = useRef<HTMLDivElement>();
  const onRefChange = useCallback(
    (node: HTMLDivElement) => {
      ref.current = node;
      if (node) {
        checkVisibleLetter();
      }
    },
    [checkVisibleLetter]
  );
  const [magIndex, setMagIndex] = useState<number>();

  const getClosestValidLetterIndex = useCallback(
    (alphabet: string[], visualLetterIndex: number, preferNext: boolean) => {
      const lowercaseAlphabet = alphabet.map((l) => l.toLowerCase());
      const lowercaseValidLetters = validLetters.map((l) => l.toLowerCase());
      const validLettersAsNumbers = lowercaseValidLetters.map((l) =>
        lowercaseAlphabet.indexOf(l)
      );

      return validLettersAsNumbers.length > 0
        ? validLettersAsNumbers.reduce((prev, curr) =>
            preferNext
              ? Math.abs(curr - visualLetterIndex) >
                Math.abs(prev - visualLetterIndex)
                ? prev
                : curr
              : Math.abs(curr - visualLetterIndex) <
                Math.abs(prev - visualLetterIndex)
              ? curr
              : prev
          )
        : null;
    },
    [validLetters]
  );

  const setLetterFromCoordinates = useCallback(
    (x: number, y: number) => {
      if (!ref.current) throw Error("ref is undefined");
      if (exactX) {
        const rightX = ref.current.getBoundingClientRect().right;
        const leftX = ref.current.getBoundingClientRect().left;

        componentActive.current = x > leftX && x < rightX;
        if (!componentActive.current) {
          visualLetterIndex.current = null;
          return;
        }
      }

      const height = ref.current.clientHeight;
      //Letters drew outside the viewport or host padding may cause values outsize height boundries (Usage of min/max)
      const top = Math.min(
        Math.max(0, y - ref.current.getBoundingClientRect().top),
        height
      );

      let topRelative = (top / height) * (visibleLetters.length - 1);
      const preferNext = Math.round(topRelative) < topRelative;
      topRelative = Math.round(topRelative);

      setMagIndex(topRelative);

      //Set visualLetterIndex to the closest valid letter
      visualLetterIndex.current = getClosestValidLetterIndex(
        visibleLetters,
        topRelative,
        preferNext
      );
      let foundLetter;
      let finalLetterIndex;
      if (lettersShortened) {
        finalLetterIndex = getClosestValidLetterIndex(
          alphabet,
          topRelative,
          preferNext
        );
        foundLetter = alphabet[finalLetterIndex];
      } else {
        finalLetterIndex = visualLetterIndex.current;
        foundLetter = visibleLetters[finalLetterIndex];
      }
      setActiveLetter(foundLetter);
    },
    [
      exactX,
      visibleLetters,
      getClosestValidLetterIndex,
      lettersShortened,
      alphabet,
    ]
  );

  const focusEvent = useCallback(
    (x: number, y: number, type: string) => {
      componentActive.current = type !== "click";

      setLetterFromCoordinates(x, y);

      if (
        lastTriggeredLetter.current !== activeLetter &&
        (navigateOnHover || !type.includes("mouse"))
      ) {
        onLetterSelected((lastTriggeredLetter.current = activeLetter));
      }
    },
    [activeLetter, navigateOnHover, onLetterSelected, setLetterFromCoordinates]
  );

  const getLetterStyle = useCallback(
    (index: number) => {
      if (
        magIndex === undefined ||
        (!magnifyDividers && visibleLetters[index] === overflowDivider) ||
        (disableInvalidLetters && !isValid(visibleLetters[index]))
      )
        return {};
      const lettersOnly = visibleLetters.filter((l) => l !== overflowDivider);

      const mappedIndex = Math.round(
        (index / visibleLetters.length) * lettersOnly.length
      );
      const mappedMagIndex = Math.round(
        (magIndex / visibleLetters.length) * lettersOnly.length
      );

      let relativeIndex = magnifyDividers
        ? Math.abs(magIndex - index)
        : Math.abs(mappedMagIndex - mappedIndex);

      const magnification =
        relativeIndex < magnificationCurve.length - 1
          ? magnificationCurve[relativeIndex] * (magnificationMultiplier - 1) +
            1
          : 1;
      const style: any = {
        transform: `scale(${magnification})`,
        zIndex: magIndex === index ? 1 : 0,
      };
      return componentActive && letterMagnification ? style : {};
    },
    [
      magIndex,
      magnifyDividers,
      visibleLetters,
      overflowDivider,
      disableInvalidLetters,
      isValid,
      magnificationCurve,
      magnificationMultiplier,
      componentActive,
      letterMagnification,
    ]
  );

  useEffect(() => {
    const timeout = setInterval(() => {
      if (ref.current) checkVisibleLetter();
    }, 100);

    return () => clearTimeout(timeout);
  }, [checkVisibleLetter]);

  useEffect(() => {
    console.debug(`Active letter is: ${activeLetter}`);
  }, [activeLetter]);

  const onMouseEvent = useCallback(
    (event: MouseEvent) => {
      focusEvent(event.clientX, event.clientY, event.type);
    },
    [focusEvent]
  );
  const onTouchEvent = useCallback(
    (event: TouchEvent) => {
      focusEvent(
        event.touches[0].clientX,
        event.touches[0].clientY,
        event.type
      );
    },
    [focusEvent]
  );
  return (
    <Container>
      <div
        className="letters"
        ref={onRefChange}
        onClick={(e) => onMouseEvent(e)}
        onMouseEnter={onMouseEvent}
        onMouseMove={onMouseEvent}
        onTouchMove={onTouchEvent}
        onTouchStart={onTouchEvent}
      >
        {visibleLetters.map((letter, idx) => (
          <div
            className={classNames("letter", {
              "letter-disabled": disableInvalidLetters && !isValid(letter),
            })}
            style={getLetterStyle(idx)}
          >
            {letter}
          </div>
        ))}
      </div>
    </Container>
  );
};
