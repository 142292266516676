import React, { useCallback } from "react";

export interface useMonitorOptions {
  timeout: number;
  onTimeOut: Function;
}

function useInactivityTimeOut({ timeout, onTimeOut }: useMonitorOptions) {
  const fn = useCallback(() => {
    onTimeOut && onTimeOut();
  }, [onTimeOut]);

  React.useEffect(() => {
    let logoutTimeout;
    if (!timeout) return;

    const setTimeouts = () => {
      logoutTimeout = setTimeout(fn, timeout);
    };

    const clearTimeouts = () => {
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [fn, timeout]);
}

export default useInactivityTimeOut;
