import {
  AccountInfo,
  AuthenticationResult,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { NonIdealState, Spinner, SpinnerSize } from "@blueprintjs/core";
import { useLocalStorageState, useSessionStorageState } from "ahooks";
import * as React from "react";
import { useLocation } from "react-router-dom";

import { ErrorDetails, UserApi, UserProfileDto } from "../api";
import Center from "../components/Center";
import { baseUrl } from "../hooks/useApiService";
import { useFrontendSettings } from "../hooks/useFrontendSettings";
import useInactivityTimeOut from "../hooks/useInactivityTimeOut";
import { useTl } from "../hooks/useTl";
import { ETLCodes } from "../locales";

interface IAuthProviderProps {
  children: React.ReactNode;
}

export type logoutReason = "user-requested" | "timeout";

interface IAuthContext {
  reloadUser: () => Promise<void>;
  logout: (reason?: logoutReason) => void;
  isLoginIn: boolean;
  user?: UserProfileDto;
  logoutReason?: string;
  loginError?: ErrorDetails;
  isLoggedIn: boolean;
  getAccessToken: () => Promise<string>;
}

const AuthContext = React.createContext<IAuthContext>(null);

const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({
  children,
}) => {
  // Load from localstorage
  const { t } = useTl();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [logoutReason, setLogoutReason] = React.useState<string>();
  const [userProfile, setUserProfile] = React.useState<UserProfileDto>(null);
  const { frontendSettings } = useFrontendSettings();
  const [loginError, setCurrentError] = React.useState<ErrorDetails>();
  const location = useLocation();

  const [lastAccountName, setLastAccountName] =
    useLocalStorageState<string>("wap_account_name");
  const msalInstance = React.useMemo(() => {
    if (frontendSettings) {
      return new PublicClientApplication({
        auth: {
          clientId: frontendSettings.azureClientId,
          authority: frontendSettings.azureAuthority,
        },
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
          loggerOptions: {
            loggerCallback: (logLevel, message) => {
              // eslint-disable-next-line no-console
              console.log("[MSAL]", message);
            },
            piiLoggingEnabled: true,
            logLevel: LogLevel.Warning,
          },
        },
      });
    }
  }, [frontendSettings]);

  const [azureAdAccount, setAzureAdAccount] = React.useState<AccountInfo>();
  const [, setRedirectUri] = useSessionStorageState<string>("redirect_uri");

  const getAccessToken = React.useCallback(async () => {
    let refreshedToken: AuthenticationResult;
    try {
      if (azureAdAccount) {
        refreshedToken = await msalInstance.acquireTokenSilent({
          account: azureAdAccount,
          scopes: [frontendSettings.azureApiScope],
        });
        return "Bearer " + refreshedToken.accessToken;
      }
    } catch (error) {
      console.error("Unable to get token silently", error);
      await msalInstance.loginRedirect({
        scopes: [frontendSettings?.azureApiScope],
        loginHint: lastAccountName,
      });
    }

    return null;
  }, [azureAdAccount, lastAccountName, frontendSettings, msalInstance]);

  const loginProcess = React.useCallback(() => {
    setIsLoading(true);
    const previousPath = location.pathname;
    return msalInstance
      .handleRedirectPromise()
      .then((redirectResponse) => {
        if (redirectResponse !== null) {
          setAzureAdAccount(redirectResponse.account);
          setLastAccountName(redirectResponse.account.username);
        } else {
          setRedirectUri(previousPath);
          const account = msalInstance.getAccountByUsername(lastAccountName);
          if (account) {
            console.log("Local account found ...", account);

            setAzureAdAccount(account);
          } else {
            console.log("No account found, login with azure ad ! ");

            void msalInstance.loginRedirect({
              scopes: [frontendSettings?.azureApiScope],
              loginHint: lastAccountName,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    frontendSettings?.azureApiScope,
    lastAccountName,
    location.pathname,
    msalInstance,
    setLastAccountName,
    setRedirectUri,
  ]);

  React.useEffect(() => {
    if (msalInstance && !azureAdAccount && !isLoading) {
      void loginProcess();
    }
  }, [azureAdAccount, isLoading, loginProcess, msalInstance]);

  const userApi = React.useMemo(() => {
    return new UserApi({
      basePath: baseUrl,
      apiKeyPromise: getAccessToken,
      middlewares: [],
    });
  }, [getAccessToken]);

  const loadUser = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const userProfile = await userApi.getProfile();
      setUserProfile(userProfile);
    } catch (error) {
      console.error(error);
      setCurrentError(error);
    } finally {
      setIsLoading(false);
    }
  }, [userApi]);

  React.useEffect(() => {
    if (azureAdAccount && !userProfile) {
      void loadUser();
    }
  }, [azureAdAccount, loadUser, userProfile]);

  const logout = React.useCallback(
    async (reason?: string) => {
      setLogoutReason(reason);

      await userApi.logout();
      await msalInstance.logoutRedirect({
        account: azureAdAccount,
      });

      setAzureAdAccount(null);
      setUserProfile(null);
    },
    [azureAdAccount, msalInstance, userApi]
  );

  const isLoggedIn = React.useMemo(() => !!userProfile, [userProfile]);

  useInactivityTimeOut({
    timeout:
      1000 * 60 * frontendSettings?.userInactivityAutoLogoutInMinutes ?? 0, // 20 Minutes
    onTimeOut: logout,
  });

  return isLoading || !azureAdAccount ? (
    <Center>
      <NonIdealState
        title={t(ETLCodes.AuthenticationInProgress)}
        icon={<Spinner size={SpinnerSize.LARGE} />}
      />
    </Center>
  ) : (
    <AuthContext.Provider
      value={{
        reloadUser: loadUser,
        getAccessToken,
        logout,
        user: userProfile,
        isLoginIn: isLoading,
        logoutReason,
        loginError,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
