import React from "react";
import { Navigate, useLocation } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { ERoutes } from "../AppRouter";

export interface RequireAuthProps {
  children?: React.ReactNode;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn, user, isLoginIn } = useAuth();
  if ((!isLoggedIn || !user?.presenceApp) && !isLoginIn) {
    return (
      <Navigate to={ERoutes.unauthorized} state={{ from: location }} replace />
    );
  }
  return <>{children}</>;
};

export default RequireAuth;
