import React from "react";
import { RecoilRoot } from "recoil";
import { DialogProvider } from "./contexts/DialogContext";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalDataProvider } from "./contexts/GlobalDataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
      refetchOnWindowFocus: false,
    },
    mutations: {
      // mutation options
    },
  },
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FunctionComponent<AppProviderProps> = (
  props
) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <DialogProvider>
            <HashRouter>
              <AuthProvider>
                <GlobalDataProvider>{props.children}</GlobalDataProvider>
              </AuthProvider>
            </HashRouter>
          </DialogProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </React.Suspense>
  );
};
