import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export interface CenterProps {
  children?: React.ReactNode;
}

export const Center: React.FC<CenterProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Center;
