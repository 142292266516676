import React from "react";
import styled from "styled-components";
import classNames from "classnames";

type StackMode = "vertical" | "horizontal";

const Container = styled.div<{ gap: string }>`
  display: flex;

  &.vertical {
    flex-direction: column;
    & > * + * {
      margin-top: ${(p) => p.gap};
    }
  }

  &.horizontal {
    flex-direction: row;
    & > * + * {
      margin-left: ${(p) => p.gap};
    }
  }
`;

export interface StackProps {
  children?: React.ReactNode;
  gap?: number | string;
  type?: StackMode;
  className?: string;
}

export const Stack: React.FC<StackProps> = ({
  children,
  gap,
  type = "vertical",
  className,
}) => {
  const computedGap = React.useMemo(
    () => (typeof gap == "number" ? gap + "px" : gap),
    [gap]
  );

  return (
    <Container
      gap={computedGap}
      className={classNames(type, { [`${className}`]: !!className })}
    >
      {children}
    </Container>
  );
};

export default Stack;
