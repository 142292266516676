import React from "react";
import styled from "styled-components";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { Button, ButtonGroup, Colors, Icon, IconName } from "@blueprintjs/core";
import { ButtonProps } from "nsitools-react";

const pageTitleAtom = atom({
  key: "page-title",
  default: "",
});

export const useGetPageTitle = () => useRecoilValue(pageTitleAtom);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .page-container {
    flex: 1;
    padding: 0.5rem 0.5rem 80px;
    overflow: auto;
  }

  .bottom-section {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export interface BottomButtonProps
  extends Omit<ButtonProps, "icon" | "position"> {
  icon: IconName;
}

const BottomButton: React.FC<BottomButtonProps> = ({ icon, ...props }) => {
  return <Button {...props} icon={<Icon icon={icon} size={30} />}></Button>;
};

export interface PageProps {
  title?: string;
  children?: React.ReactNode;
  onGoBack?: () => unknown;
  onRefresh?: () => unknown;
  refreshButtonProps?: BottomButtonProps;
  onNext?: () => unknown;
  nextButtonProps?: BottomButtonProps;
}

export const Page: React.FC<PageProps> = ({
  title,
  children,
  onNext,
  onRefresh,
  onGoBack,
  refreshButtonProps,
  nextButtonProps,
}) => {
  const setPageTitle = useSetRecoilState(pageTitleAtom);

  React.useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);

  return (
    <Container>
      <div className={"page-container"}>{children}</div>
      <div className={"bottom-section"}>
        <ButtonGroup
          fill={true}
          large={true}
          style={{ height: 80, background: Colors.WHITE }}
        >
          <BottomButton
            icon={"chevron-left"}
            disabled={!onGoBack}
            onClick={onGoBack}
          />
          <BottomButton
            icon={"refresh"}
            disabled={!onRefresh}
            onClick={onRefresh}
            {...refreshButtonProps}
          />
          <BottomButton
            icon={"chevron-right"}
            intent={"primary"}
            disabled={!onNext}
            onClick={onNext}
            {...nextButtonProps}
          ></BottomButton>
        </ButtonGroup>
      </div>
    </Container>
  );
};

export default Page;
