import { Button, Divider, Drawer, Menu, Navbar } from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { useToggle } from "ahooks";
import React from "react";
import styled from "styled-components";

import { useGetPageTitle } from "./components/Page";
import { useCommonHooks } from "./hooks/useCommonHooks";
import { ETLCodes } from "./locales";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > .inner-container {
    padding-top: 50px;
  }
`;

export interface AppShellProps {
  children?: React.ReactNode;
}

export const AppShell: React.FC<AppShellProps> = ({ children }) => {
  const { isLoggedIn, t, logout, user } = useCommonHooks();
  const [state, { toggle }] = useToggle(false);
  const pageTitle = useGetPageTitle();

  return (
    <Container>
      <Navbar fixedToTop={true}>
        <Navbar.Group align={"left"}>
          {isLoggedIn && (
            <Button
              icon={"menu"}
              minimal={true}
              large={false}
              onClick={toggle}
            />
          )}
          <Divider></Divider>
          <h1>{pageTitle}</h1>
        </Navbar.Group>
      </Navbar>
      <Drawer
        isOpen={state}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        position={"left"}
        onClose={toggle}
        title={t(ETLCodes.Menu)}
      >
        <Menu large={true}>
          <MenuItem2 text={`${user?.nom} ${user?.prenom}`} disabled />
          <MenuItem2
            icon={"log-out"}
            text={t(ETLCodes.Logout)}
            onClick={() => {
              toggle();
              logout("user-requested");
            }}
          />
        </Menu>
      </Drawer>
      <div className={"inner-container"}>{children}</div>
    </Container>
  );
};

export default AppShell;
